export const stipends = ['stipend', 'laptop', 'data', 'fees'];
export const stipendsColors = ['#5801ff', '#FD5A33', '#08B7B7', '#FFB600'];
export const BUTTON_TYPE = 'secondary';

export const StipendCategory = {
  DATA: 'data',
  FEES: 'fees',
  LAPTOP: 'laptop',
  STIPEND: 'stipend'
};

export const TestId = {
  BANNER_TEST_ID: 'banner-test-id',
  BTN_TEST_ID: 'btn-test-id',
  DEFAULT_HERO_TEST_ID: 'hero-test-id'
};

export const HERO_PARAGRAPH = 'Give your learning goals a BOOST';
export const STATIC_HERO_TEXT = 'In need of some stipend for your learning?';
export const APP_WINDOW_CLOSED_BANNER_TEXT =
  'Application window is currently closed and opens on the 1st of next month. Click the “Notify me” button to be notified when the window opens.';
