export const headText = 'PARTNERS';
export const description = 'We have collaborated with';
export const STATS = 'the following communities that share our vision and mission';

export const Texts = {
  HEADER: 'PARTNERS',
  COLLABORATE: 'We have collaborated with',
  STATS: 'the following communities that share our vision and mission'
};

export const TestId = {
  WRAPPER: 'wrapper-el',
  HEAD_TEXT: 'head_text-el',
  STATS: 'stats-el',
  DESCRIPTION: 'desc-el'
};
