export const tagEvents = {
  //events
  supportButton: 'support_button_clicked',
  supportMenuLink: 'support_menu_link_clicked',
  successModalButton: 'success_modal_button',
  //categories
  buttonCategory: 'button',
  //actions
  donateNow: 'donate_now_trigger',
  closeDonationSuccessModal: 'close_donation_success_modal',
  //labels
  donateBtnLabel: 'Support a Learner',
  closeBtnLabel: 'Close'
};
