export const constants = {
  HEADER: `Create an account`,
  PROMPT: `As part of your stipend request process, we are creating an account for you, 
    to help save your application process and information so as to avoid your details being discarded`,
  NO: `No, Thanks`,
  CREATE: `Yes, Create my account`,
  DISMISS: 'Dismiss',
  QUOTE: `"It does not matter how slowly you go, as long as you do not stop." - Confucius`
};

export const TestId = {
  SUBMIT_ID: "ui_component_id",
  IMAGE_ID: "ui_image_id",
  ERROR_BUTTON_ID: "error_button_id",
  SUCCESS_IMAGE_ID: "ui_image_id",
  SUCCESS_BUTTON_ID: "success_button_id",

}