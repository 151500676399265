export const constants = {
  dashboard: 'My Dashboard',
  username: 'Chiehiura',
  PROMPT: 'Check your email for a verification link as your account is unverified'
};

export const Quote = {
  content: 'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got this.',
  author: 'Chantal Sutherland'
};
export const tab = ['Most recent application', 'My application history'];
export const submitted = 'Here is what you submitted';
export const tableHead = ['ID', 'Stipend Category', 'Application Status', 'Date of submission', 'Time of submission', 'Action'];
export const submissionTableHead = ['ID', 'Stipend Category', 'Application Status', 'Date of submission', 'Time of submission'];
export const back = 'Back';

export const recent = [
  {
    id: 1,
    Stipend_Category: 'Laptop stipend',
    Application_Status: 'Recieved',
    Date_of_submission: '1 / September / 2023',
    Time_of_submission: '11:00 AM WAT'
  }
];

export const history = [
  {
    id: 1,
    Stipend_Category: 'Laptop stipend',
    Application_Status: 'Recieved',
    Date_of_submission: '1 / September / 2023',
    Time_of_submission: '11:00 AM WAT'
  }
];

export const TestId = {
  HOME: 'homeId',
  USER: 'userId',
  QUOTE: 'quoteId',
  TABLE: 'tableId',
  VERIFY_BANNER: 'verify-banner-test-id'
};
