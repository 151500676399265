import React from 'react';
import AboutUsSection from '../../sections/AboutUs';
import RequestSection from '../../sections/Request';
import MeetTheTeam from '../../sections/MeetTheTeam/MeetTheTeam';

const AboutUs = () => {
  return (
    <>
      <AboutUsSection />
      <MeetTheTeam />
      <RequestSection />
    </>
  );
};

export default AboutUs;
