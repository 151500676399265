export const Text = {
  BUTTON_LABEL: 'Take me to Login',
  EMAIL_EXIST_TEXT: 'Hey! We know you.',
  LOGIN_TEXT: 'Click on the button below to go to the login page to view your current application(s).'
};

export const CLOSEMODAL_ALT_TEXT = 'closeModal';

export const TestId = {
  EMAIL_TEST_ID: 'email-exist-id',
  CLOSEMODAL_TEST_ID: 'closeModal-id'
};
