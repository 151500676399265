export const constants = {
  title: 'HOW MUCH CAN I DONATE?',
  text: 'Every contribution, regardless of the amount, is a cornerstone in building a brighter future for a learner',
  slider: 'Drag the slider to see impact of each amount'
};

export const TestId = {
  COMPONENT_ID: 'donation_range',
  TITLE_ID: 'donation_range_id',
  RANGE_CONTAINER_ID: 'range_container_id',
  DONATION_TEXT_ID: 'donation_range_text_id',
  DONATION_INPUT: 'donation_range_input_id',
  DONATION_BUTTON: 'donation_range__button'
};
