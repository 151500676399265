export const TestConstants = {
  componentTestId: 'Testimonial_testId',
  leftBtnTestId: 'leftBtnTestId',
  rightBtnTestId: 'rightBtnTestId',
  paginationTestId: 'paginationTestId',
  pagTestId: 'pagtionTestId'
};

export const testimonialStatic = {
  title: 'Testimonials',
  subtitle: 'HEAR FROM OUR AMBASSADORS AND BENEFICIARIES'
};
