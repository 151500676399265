export const paths = [
  {
    title: 'Home',
    path: '/support-a-learner'
  },
  {
    title: 'Our Impact',
    path: '/impact'
  }
];

export const totalAmount = 32833036;
export const beneficiaries = '600+';

export const ImpactLabels = {
  TOTAL_DISBURSED: 'TOTAL AMOUNT DISBURSED',
  COMMUNITY_PARTNERSHIPS: 'COMMUNITY PARTNERSHIPS',
  BENEFICIARIES: 'NO OF BENEFICIARIES',
  STATES_REACHED: 'STATES REACHED',
  VIEW_REPORT_BUTTON_LABEL: 'See Monthly Reports',
  IMPACT_REPORT: 'View Impact Report',
  HEADER: 'Our Impact',
  SUPPORT: 'Donate Now',
  DONATE: '/support-a-learner/donate',
  PARTNER_HEADER: 'MAKE AN IMPACT WITH US',
  OVERVIEW: 'Overview of Our Impact Numbers',
  REPORTS_PATH: '/impact/reports',
  VIDEO: 'https://www.youtube.com/embed/e6aNgMiaQ30?si=rIpgEGm-0sBa_Brx&autoplay=1',
  SUB_HEADER: 'Watch the interview below with one of our beneficiaries and see the tangible impact of our monthly stipend program.',
  PARTNER_SUB_HEADER: 'Partner with us to expand our reach and provide education opportunities to those who need them most.'
};

export const TestId = {
  HEAD_TEXT: 'head_text-el',
  PARTNER_HEAD_TEXT: 'partner_head_text-el',
  SUB_HEADER_TEXT: 'sub_header_text-el',
  PARTNER_SUB_HEADER_TEXT: 'sub_header_text-el',
  OVERVIEW_TEXT: 'overview-el'
};
