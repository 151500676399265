export const DEFAULT_BUTTON_LABEL = 'Add button';

export const ButtonType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PLAIN: 'plain'
};

export const ClassName = {
  ROOT_BUTTON: 'edustipend-button',
  PRIMARY_BUTTON: 'edustipend-button--primary',
  SECONDARY_BUTTON: 'edustipend-button--secondary',
  ICON: 'icon-button',
  PLAIN_BUTTON: 'edustipend-button--plain'
};

export const IconPosition = {
  BACK: 'back',
  FRONT: 'front'
};

export const TestId = {
  DEFAULT_BUTTON_TEST_ID: 'default-button-test-id'
};
