export const constants = {
  HEADER: 'Ready to send it off? 🚀',
  PARA1: `You've reached the final step of your application journey! Now, let's submit your hard work and leave the rest to us.`,
  PARA2: `Once you've hit that submit button, our team will carefully review your application. If you're selected for the category you applied to, we'll reach out to you using the email you provided.
    Beneficiaries will be contacted at most three weeks from Friday 15, which is when the application closes.`,
  PARA3:
    'No need to contact us, you can follow us on our social media pages: @edustipend - Twitter, Instagram and Facebook where we will publish more information.',
  PARA4: `We wish you all the best and as always, we are rooting for you!`,
  PARA5: `In Your Service`,
  PARA6: ` The Edustipend Team ❤️`,
  QUOTE: `"Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got this." - Chantal Sutherland`,
  NEW_APPLICATION_HEADER: "Ready to send it off?",
  NEW_APPLICATION_PARA1: "You've reached the final step of your application journey!, now let's submit your hard work and leave the rest to us.",
  NEW_APPLICATION_PARA2: `Once you hit the submit button, our team will carefully review your application. If you're selected  for the category you applied to, 
  we'll reach out to you using the email you provided. Beneficiaries will be contacted at most three weeks from Friday 15, which is when the application closes.`,
  PROMPT:"Check your email for a verification link as your account is unverified",
};

export const toastNotifications = {
  UPDATING: {
    loading: 'Submitting edited application',
    id: 'edit-app',
    message: 'Updated successfully'
  },
  SUBMITTING: {
    loading: 'Submitting new application',
    id: 'new-app',
    message: 'Submitted successfully'
  },
  ONE_CLICK: {
    loading: 'Submitting new application',
    id: 'one-click',
    message: 'Submitted successfully',
    error: 'Please do well to verify your account :('
  },
  ERROR: {
    message: 'Oops! Something went wrong.'
  }
};

export const TestId = {
  COMPONENT_TEST_ID: "submit_component_id",
  IMAGE_ID: "submit_image_id",
  HEADER_ID: "submit_header_id",
  BACK_ID: "submit_back_id",
  SUBMIT_ID: "submit_submit_id",
}