export const constants = {
  HEADER: 'Reset your password',
  EMAIL: 'Email',
  CODE: 'Code',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_MISMATCH: 'Password mismatch',
  PASSWORD_MIN_LENGTH_ERR: 'A minimum of 8 characters is required'
};

export const TestId = {
  COMPONENT_TEST: 'component',
  HEADER_TEST: 'header',
  BUTTON_TEST: 'button',
  MODAL_TEST: 'modal',
  ERROR_TEST: 'error'
};
