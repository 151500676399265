export const headText = 'OUR IMPACT SO FAR';

export const description = 'We are committed to securing futures through providing learning resources';

export const subText =
  'Since 2022, we have served learners from different socio-economic, ethnic and religious backgrounds, empowering them with laptops, data stipends and course certification fees.';

export const numbers = [
  {
    label: 'Beneficiaries',
    value: 600
  },
  {
    label: 'Amount Disbursed',
    value: 32833036
  },
  {
    label: 'States Reached',
    value: 36
  },
  {
    label: 'Community Partnerships',
    value: 5
  }
];

export const btnLabels = {
  view: {
    label: 'View our impact',
    path: '/impact',
    type: 'plain'
  },
  support: {
    label: 'Support a Learner',
    path: '/support-a-learner/donate',
    type: 'secondary'
  }
};

export const TestId = {
  WRAPPER: 'wrapper-el',
  HEAD_TEXT: 'head_text-el',
  IMAGE: 'image-el',
  DESCRIPTION: 'desc-el',
  SUB_TEXT: 'sub_text-el'
};
