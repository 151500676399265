export const constant = {
  dataArr: [
    {
      category: 'laptop',
      text: 'can help get a laptop',
      textAlt: 'can help get laptops',
      cost: 400000
    },
    {
      text: 'can help pay for online courses',
      cost: 25000
    },
    {
      text: 'can help get data subscription',
      cost: 10000
    }
  ],
  invalidAmount: "Oops! Sorry, amount can't be less than ₦1,000",
  gratitude: 'This amount will help us empower a learner'
};
