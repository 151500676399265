import ubong from '../../../../assets/testimonials/ubon.png';
import instagram from '../../../../assets/social/InstagramLogoNew.svg';
import twitter from '../../../../assets/social/TwitterLogoNew.svg';
import linkedin from '../../../../assets/social/LinkedinLogoNew.svg';

export const cardData = [
  {
    id: 1,
    name: 'Edeke Emmanueli',
    status: 'Applicant',
    testimonial: `The consistency of Edustipend is what amazes me the most, for me that top notch. I am joining @edustipend to celebrate their one year anniversary and also to express my gratitude to all the donors and team, and wish them all the best.`,
    image: 'https://pbs.twimg.com/profile_images/1533336161799786496/ruIQo05b.jpg',
    socials: [
      {
        id: 0,
        media: 'twitter',
        img: twitter,
        link: 'https://twitter.com/EBakeCode/status/1687423151280369664'
      },
      {
        id: 1,
        media: 'linkedin',
        img: linkedin,
        link: '#'
      },
      {
        id: 2,
        media: 'instagram',
        img: instagram,
        link: '#'
      }
    ]
  },
  {
    id: 2,
    name: 'Blessing Agabus',
    status: 'Public',
    testimonial: `@edustipend community is doing a lot better to the heart and career-building of its beneficiaries. I introduced my girlfriend last month, she got a voucher aid. Mine is a laptop, I'm never giving up.`,
    image: 'https://pbs.twimg.com/profile_images/1645929666434281473/fLt2QeJL.jpg',
    socials: [
      {
        id: 0,
        media: 'twitter',
        img: twitter,
        link: 'https://twitter.com/FineAgabus/status/1622955318362734593'
      },
      {
        id: 1,
        media: 'linkedin',
        img: linkedin,
        link: '#'
      },
      {
        id: 2,
        media: 'instagram',
        img: instagram,
        link: '#'
      }
    ]
  },
  {
    id: 3,
    name: 'Ubon Udonkang ',
    status: 'Ambassador',
    testimonial: `With @edustipend, I went from an applicant to becoming a beneficiary, then an ambassador then securing my first offer as a UX designer`,
    image: ubong,
    socials: [
      {
        id: 0,
        media: 'twitter',
        img: twitter,
        link: 'https://twitter.com/ubondesigner/status/1663839403188400128'
      },
      {
        id: 1,
        media: 'linkedin',
        img: linkedin,
        link: '#'
      },
      {
        id: 2,
        media: 'instagram',
        img: instagram,
        link: '#'
      }
    ]
  },

  {
    id: 4,
    name: 'Priscilla Adindeh',
    status: 'Beneficiary',
    testimonial: `You've impacted the lives of so many and I'm so grateful to be a part of those that have benefited from the monthly stipend. Thanks for believing in us, our passion and dreams. Many more years to celebrate. Congratulations 🎉`,
    image: 'https://pbs.twimg.com/profile_images/1621188582672142338/sw_YS5pQ.jpg',
    socials: [
      {
        id: 0,
        media: 'twitter',
        img: twitter,
        link: 'https://twitter.com/PAdindeh/status/1684293641823477766?'
      },
      {
        id: 1,
        media: 'linkedin',
        img: linkedin,
        link: '#'
      },
      {
        id: 2,
        media: 'instagram',
        img: instagram,
        link: '#'
      }
    ]
  },
  {
    id: 5,
    name: 'Philip Ishola',
    status: 'Beneficiary',
    testimonial: `Angels still exists in humans. @edustipend is an angelic organisation. I want to appreciate the @edustipend team for believing in my dream and giving a proportional support to my career. Thank you, I will definitely pay it forward.`,
    image: 'https://pbs.twimg.com/profile_images/1678560559740993537/BuAfWkcj.jpg',
    socials: [
      {
        id: 0,
        media: 'twitter',
        img: twitter,
        link: 'https://twitter.com/prereloluwa/status/1601244577520046082'
      },
      {
        id: 1,
        media: 'linkedin',
        img: linkedin,
        link: '#'
      },
      {
        id: 2,
        media: 'instagram',
        img: instagram,
        link: '#'
      }
    ]
  }
];
