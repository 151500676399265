export const constants = {
  HEADER: 'Email Verified Successfully',
  SUCCESS_BTN: 'Proceed',
  ERR_HEADER: 'Email verification failed',
  ERR_TEXT: `For some reasons your email  could not be verified.
  We are sorry about this. Please try again`,
  ERR_BTN: 'Return',
  TRY_AGAIN: 'Try again'
};

export const TestId = {
  VERIFIED_ID: "verified_id",
  UNVERIFIED_ID: "unverified_id",
}