export const constants = {
  HEADER: 'Let’s Meet You',
  SUBHEADER: `Whew! Now that we're done with all the serious stuff, can we get to meet you?`,
  FULLNAME: 'First and Last name',
  FULLNAME_PH: 'FullName',
  EMAIL: 'Email Address',
  EMAIL_PH: 'Email',
  DOB: 'Date Of Birth',
  MTH_PH: 'Month',
  DAY_PH: 'Day',
  YR_PH: 'Year',
  GENDER: 'Gender',
  GENDER_PH: 'Select Gender',
  STATE: 'State Of Origin',
  STATE_PH: 'Select Your State Of Origin',
  SOCIAL: 'Social Media Handle',
  SOCIAL_PH: 'Select Your Social Media Handle',
  TWITTER: 'Twitter Handle',
  TWITTER_PH: '@',
  FB: 'Facebook Handle',
  FB_PH: '',
  IG: 'Instagram Handle',
  IG_PH: '@',
  LINKEDIN: 'LinkedIn Handle',
  LINKEDIN_PH: '@',
  PASSWORD: 'Password',
  PASSWORD_PH: 'Create your password',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONFIRM_PASSWORD_PH: 'Re-enter your password',
  AD: `One last question, well almost the last one.
    How did you hear about Edustipend?`,
  AD_PH: 'Please select',
  QUOTE: '"The future depends on what you do today." - Mahatma Gandhi',
  DOB_TEXT: `We want to be able to support everyone fairly regardless of age. 
    Please select your correct date of birth as this cannot be changed after registration`,
  GENDER_TEXT: `We want to be able to support everyone fairly regardless of gender.`,
  TWITTER_TEXT: `Please share your twitter handle`,
  FB_TEXT: `Please share your facebook handle`,
  IG_TEXT: `Please share your instagram handle`,
  LINKEDIN_TEXT: `Please share your linkedin handle`,
  SOCIAL_HANDLES: ['Twitter', 'Facebook', 'Instagram', 'LinkedIn'],
  MONTHS_OPTION: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  GENDER_OPTION: ['Male', 'Female', 'Non-Binary'],
  STATE_OPTION: [
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Federal Capital Territory',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara'
  ],
  REFERRAL_SOURCES: ['Facebook', 'LinkedIn', 'Instagram', 'Twitter', 'Community', 'Google Search'],
  PASSWORD_MIN_LENGTH_ERR: 'A minimum of 8 characters is required',
  PASSWORD_MISMATCH_ERR: 'password mismatch'
};

export const TestId = {
  COMPONENT_TEST_ID: 'step4_component_id',
  FORM_ID: 'step4_form_id',
  HEADER_ID: 'step4_header_id',
  NEXT_ID: 'step4_next_id',
  BACK_ID: 'step4_back_id'
};
