export const constants = {
  CTA_BUTTON_TEXT: 'Send reset link',
  HEADER: 'Password forgotten?',
  TEXT: "No worries, you're not alone! Just enter the email address associated and we'll email you a link to help you reset your password.",
  FOOT_NOTE: 'Please check your “Spam folder” if you don’t see the email in your inbox'
};

export const TestId = {
  COMPONENT_TEST: 'component_id',
  HEADER_TEST: 'header_id',
  TEXT_TEST: 'text_id',
  BUTTON_TEST: 'button_id',
  FOOT_NOTE_TEST: 'footnote_id'
};
