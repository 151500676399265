export const routesConstant = {
  AMBASSADOR_PROGRAM: '/ambassador-program',
  SUPPORT_A_LEARNER: '/support-a-learner',
  DONATE_NOW: '/support-a-learner/donate',
  ABOUT_US: '/about-us',
  APPLICATION: '/application',
  DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/forgot-password',
  REQUEST: '/request',
  RESET_PASSWORD: '/reset-password',
  WELCOME: '/welcome',
  AT_ONE: '/at-one',
  AT_TWO: '/at-two',
  LOGIN: '/login',
  DONATION: '/donation',
  REPORTS: '/impact/reports',
  IMPACTS: '/impact',
  TRANSPARENCY_DASHBOARD: '/support-a-learner/transparency-dashboard',
  REFERRAL_PAGE: '/refer-a-friend'
};
